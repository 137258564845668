import { defineStore } from 'pinia'
import clonedeep from "clone-deep"
import { generateUUID } from "~/lib/utils.js"

const defaultFieldNames = {
  title: 'Your name',
  rich_text: 'Description',
  number: 'Number',
  select: 'Select',
  multi_select: 'Multi Select',
  date: 'Date',
  files: 'Files',
  checkbox: 'Checkbox',
  url: 'Link',
  email: 'Email',
  phone_number: 'Phone Number',
}
const defaultBlockNames = {
  'nf-text': 'Text Block',
  'nf-page-break': 'Page Break',
  'nf-page-body-input': 'Page Body Input',
  'nf-page-logo': 'Page Logo',
  'nf-page-cover': 'Page Cover Image',
  'nf-divider': 'Divider',
  'nf-image': 'Image',
  'nf-code': 'Code Block',
}

export const useWorkingFormStore = defineStore('working_form', {
  state: () => ({
    content: null,

    // Field being edited
    selectedFieldIndex: null,
    showEditFieldSidebar: null,
    showAddFieldSidebar: null,
    blockForm: null,
    draggingNewBlock: false,
  }),
  actions: {
    set(form) {
      this.content = form
    },
    setProperties(properties) {
      this.content.properties = [...properties]
    },
    openSettingsForField(index) {
      // If field is passed, compute index
      if (typeof index === 'object') {
        index = this.content.properties.findIndex(
          prop => prop.nf_id === index.nf_id,
        )
      }
      this.selectedFieldIndex = index
      this.showEditFieldSidebar = true
      this.showAddFieldSidebar = false
    },
    hideField(index) {
      // If field is passed, compute index
      if (typeof index === 'object') {
        index = this.content.properties.findIndex(
          prop => prop.nf_id === index.nf_id,
        )
      }
      this.content.properties[index].hidden = true
    },
    removeField(index) {
      // If field is passed, compute index
      if (typeof index === 'object') {
        index = this.content.properties.findIndex(
          prop => prop.nf_id === index.nf_id,
        )
      }
      this.content.properties.splice(index, 1)
    },
    closeEditFieldSidebar() {
      this.selectedFieldIndex = null
      this.showEditFieldSidebar = false
      this.showAddFieldSidebar = false
    },
    openAddFieldSidebar(index) {
      // If field is passed, compute index
      if (index !== null && typeof index === 'object') {
        index = this.content.properties.findIndex(
          prop => prop.nf_id === index.nf_id,
        )
      }
      this.selectedFieldIndex = index
      this.showAddFieldSidebar = true
      this.showEditFieldSidebar = false
    },
    closeAddFieldSidebar() {
      this.selectedFieldIndex = null
      this.showAddFieldSidebar = false
      this.showEditFieldSidebar = false
    },
    reset() {
      this.content = null
      this.selectedFieldIndex = null
      this.showEditFieldSidebar = null
      this.showAddFieldSidebar = null
    },

    resetBlockForm() {
      this.blockForm = useForm({
        type: null,
        name: null,
      })

    },
    prefillDefault(data) {
      if (data.type === 'nf-text') {
        data.content = '<p>This is a text block.</p>'
      }
      else if (data.type === 'nf-page-break') {
        data.next_btn_text = 'Next'
        data.previous_btn_text = 'Previous'
      }
      else if (
        ['nf-page-body-input', 'nf-page-logo', 'nf-page-cover'].includes(
          data.type,
        )
      ) {
        data.required = false
        data.hidden = false
        data.placeholder = null
        data.prefill = null
        data.with_title = data.type === 'nf-page-body-input'
      }
      else if (data.type === 'nf-code') {
        data.content
          = '<div class="text-blue-500 italic">This is a code block.</div>'
      }
      return data
    },
    /**
     * Generate random string id
     */
    getRandomId() {
      return (
        Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15)
      )
    },
    /**
     * Returns true if this id already exists in the form (makes sur its unique)
     * @param id
     */
    idUsedInForms(id) {
      !this.form?.properties.filter((block) => {
        return block.id === id
      }).length === 0
    },
    onAdd(newBlock, index = null) {
      if (
        (this.selectedFieldIndex === null
          || this.selectedFieldIndex === undefined) &&
        (index === null || index === undefined)
      ) {
        const newFields = clonedeep(this.content.properties)
        newFields.push(newBlock)
        this.content.properties = newFields
        this.openSettingsForField(
          this.content.properties.length - 1,
        )
      }
      else {
        const fieldIndex = typeof index === "number" ? index : this.selectedFieldIndex + 1
        const newFields = clonedeep(this.content.properties)
        newFields.splice(fieldIndex, 0, newBlock)
        this.content.properties = newFields
        this.openSettingsForField(this.selectedFieldIndex + 1)
      }
    },
    addField(type, index = null) {
      this.blockForm.type = type
      this.blockForm.name = defaultFieldNames[type]
      const data = this.prefillDefault(this.blockForm.data())
      let id = this.getRandomId()
      while (this.idUsedInForms(id))
        id = this.getRandomId()

      data.id = id
      data.nf_id = generateUUID()
      data.hidden = false
      data.help_position = 'below_input'
      if (['select', 'multi_select'].includes(this.blockForm.type))
        data[this.blockForm.type] = { options: [] }

      this.onAdd(data, index)
      this.resetBlockForm()
    },

    addBlock(type, index = null) {
      this.blockForm.type = type
      this.blockForm.name = defaultBlockNames[type]
      const data = this.prefillDefault(this.blockForm.data())
      let id = this.getRandomId()
      while (this.idUsedInForms(id))
        id = this.getRandomId()

      data.id = id
      data.nf_id = generateUUID()
      data.hidden = false
      if (
        ['nf-page-body-input', 'nf-page-logo', 'nf-page-cover'].includes(type)
      )
        data.help_position = 'below_input'

      this.onAdd(data, index)
      this.resetBlockForm()
    },

    moveField(oldIndex, newIndex) {
      const newFields = clonedeep(this.content.properties)
      const field = newFields.splice(oldIndex, 1)[0]
      newFields.splice(newIndex, 0, field)
      this.content.properties = newFields
    }
  },
  history: {}
})
